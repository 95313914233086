import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

export default function About() {
  return <Layout>
    <h1>Sofia Sundström</h1>
    <p className="heading-intro">Vägen hit</p>
    <h2>Jorden runt till Åre – från mänskliga rättigheter till digital
    transformation och cirkulär ekonomi.</h2>
    <p className="body-factbox"><em>Är du intresserad av mina professionella erfarenheter? <a href="#resume">Gå direkt till
    mitt CV</a></em>.</p>

    <StaticImage
       src="../images/sofia-sundstrom.jpg"
       alt="Sofia Sundström"
       placeholder="blurred"
       className="sofia-image"
       layout="constrained"
     />

    <p>För att ta det från början:</p>

    <p>Allt började med att min basketkompis, <strong>Maria</strong>, ville gå
    på en DJ-skola i <strong>Manchester</strong>. Vi åkte dit. Jag blev kär i
    staden, flyttade dit något år senare, lärde känna <strong>Essi</strong> som kände någon som
    höll på med capoeira. Det verkade spännande.</p>

    <p>Tillbaka i <strong>Norrköping</strong> där jag pluggade, började jag på
    capoeira. På träningen träffade jag <strong>Josefin</strong>. Hon ville bo
    i <strong>Kapstaden</strong>. Det ville jag också. Vi fick jobb på University
    of Western Cape och flyttade dit tillsammans.</p>

    <p>Det blev starten för en mängd projekt ihop: vi startade en ideell
    förening för mänskliga rättigheter och gatukonst, gjorde en filmproduktion
    om HIV/AIDS i Sverige och Sydafrika, startade Rebells fredspris, ställde ut
    på Forum for Levande Historia, arbetade med gatuteater i <strong>Bolivia</strong> och mycket
    mer.</p>

    <p>Mitt i allt detta startade jag konfront. Sen träffade jag <strong>Clas
    och Hannes</strong>, vi startade en digitalbyrå ihop i <strong>Stockholm</strong>.
    Företaget växte, uppdragen växte, vi växte. Tio år och tio barn senare
    bestämde vi oss för att upplösa företaget (men fortsätta vara de bästa av
    vänner).</p>

    <StaticImage
       src="../images/skidfamilj.jpg"
       alt="Sofia Sundström med sin familj i skidbacken."
       placeholder="blurred"
       className="familj-image"
       layout="constrained"
     />

    <p>Här är jag nu. Tillbaka i konfront. Med fokus på hållbar utveckling och
    cirkulär ekonomi. Nu bor jag i <strong>Åre</strong> med <strong>Per</strong> och
    resten av min familj. Omruskad av livets prövningar, lycklig över allt jag
    har varit med om. Fortfarande hängiven att göra världen till en bättre plats.
    Kanske tillsammans med dig?</p>

    <section className="resume-container" id="resume">
      <div className="resume columns">
        <div className="col col-1">
          <h2>Erfarenhet</h2><hr />
          <p className="super-header">Grundare</p>
          <h3>konfront.</h3>
          <p>Hållbar affärs- och samhällsutveckling med fokus på värderingsdrivet
          ledarskap, cirkulär ekonomi och digital transformation.</p>
          <p className="year">2005–</p>

          <p className="super-header">Circular Transition Officer</p>
          <h3>Cradlenet</h3>
          <p>Ett av världens äldsta nätverk för cirkulär ekonomi där jag arbetar
          med att accelerera utvecklingen av den cirkulära ekonomin och driver det
          lokala nätverket <em>Cradlenet Norr</em> tillsammans med Christian Engene. <a href="https://www.cradlenet.se/">cradlenet.se</a></p>
          <p className="year">2021–</p>

          <p className="super-header">Grundare — VD</p>
          <h3>SthlmConnection</h3>
          <p>Prisbelönt strategisk digitalbyrå för hållbara lösningar som
          får människor och organisationer att må bra. Jag grundade bolaget
          tillsammans med Clas Bohman och Hannes Lilljequist.</p>
          <p className="year">2009–2021</p>

          <figure>
            <blockquote>Jag lärde mig väldigt mycket från er etiska ståndpunkt
            av respekt för användaren.</blockquote>
            <figcaption>
              Svar i kundundersökning
            </figcaption>
          </figure>

          <p className="super-header">Informatör — Föreläsare</p>
          <h3>Dans och Cirkushögskolan</h3>
          <p>Ansvarig för högskolans informations- och kommunikationsstrategi
          och medlem i ledningsgruppen. Föreläste om entreprenörskap, varumärke
          och kommunikation.</p>
          <p className="year">2007–2011</p>

          <p className="super-header">Grundare</p>
          <h3>Streets – Youth for Justice</h3>
          <p>Ideell ungdomsorganisation för mänskliga rättigheter och gatukultur
          med projekt som <strong>Re:design</strong> med fokus på hållbart mode, <strong>Rebells&nbsp;fredspris</strong>:&nbsp;en årlig gala för fred och frid, <strong>Point of View</strong>: en
          edutainmentfilm för skolelever om Hiv/Aids i Sverige och Sydafrika, <strong>Break&nbsp;Free</strong>:&nbsp;en antirasistisk festival. Jag grundade organisationen
          tillsammans med <a href="http://followrabbit.se/bakom/">Josefin Rosales</a>.</p>
          <p className="year">2004–2011</p>

          <p className="super-header">Volontär</p>
          <h3>Sweatshop Watch</h3>
          <p>Arbetade med information och utbildning om mänskliga rättigheter
          för arbetare inom Los Angeles klädindustri.</p>
          <p className="year">2005</p>

          <p className="super-header">Assistent</p>
          <h3>University of Western Cape</h3>
          <p>Arbetade med olika uppgifter inom universitetets informationsavdelning.</p>
          <p className="year">2003</p>

          <h2>Akademisk utbildning</h2><hr />
          <p className="super-header">Linköpings universitet</p>
          <h3>Cirkulär ekonomi i teori och praktik</h3>
          <p>Projektarbete: cirkulär destinationsutveckling</p>
          <p className="year">2021</p>

          <p className="super-header">Umeå Universitet</p>
          <h3>Filosofi</h3>
          <p>Etik samt Filosofins historia</p>
          <p className="year">2020</p>

          <p className="super-header">Stockholm School of Economics<br />IFL Executive Education</p>
          <h3>Managing Professional Services</h3>
          <p className="year">2015–2016</p>

          <p className="super-header">Linköpings universitet</p>
          <h3>Civilingenjör Medieteknik</h3>
          <p>Examensarbete: Designing a user interface for web based project
          management in film production</p>
          <p className="year">1998–2005</p>

          <p className="super-header">Teologiska Högskolan i Stockholm</p>
          <h3>Mänskliga Rättigheter</h3>
          <p>C-uppsats: The Transformation of South African Newspapers</p>
          <p className="year">2002–2004</p>

          <h2>Övriga kurser</h2><hr />

          <p className="super-header">Region Jämtland Härjedalen & RISE</p>
          <h3>Cirkulära affärsmodeller och digital transformation</h3>
          <p className="year">2021</p>

          <p className="super-header">Baltic Industrial Symbiosis project</p>
          <h3>Industrial Symbiosis Practitioner Training</h3>
          <p className="year">2021</p>

          <p className="super-header">Ellen MacArthur Foundation</p>
          <h3>From Inside the Circular Economy: Beyond the Basics</h3>
          <p className="year">2021</p>

          <p className="super-header">Circular Fashion</p>
          <h3>Circular Design Training</h3>
          <p className="year">2021</p>

          <p className="super-header">Styrelseakademien</p>
          <h3>Rätt fokus i styrelsearbetet</h3>
          <p className="year">2017</p>

        </div>

        <div className="col col-2">
          <h2>Uppdrag</h2><hr />

          <p className="super-header">Föreläsare — Moderator</p>
          <h3>Cirkulär omställning för hållbar tillväxt</h3>
          <p>Inspirationsföreläsare och moderator vid Östersunds kommuns
          slutkonferens om cirkulär omställning hos Östersundsföretag inom
          upplevelse- och besöksnäring. Uppdraget utfördes tillsammans med
          rapportens författare <a href="https://www.thegoodtribe.com/">Evelina Lundqvist</a>.</p>
          <p className="year">2022</p>
          <figure>
            <blockquote>Vilka fullblodsproffs ni är! En av de tydligaste
            presentationer jag sett/hört. Imponerande!</blockquote>
          <figcaption>Feedback från deltagare</figcaption>
          </figure>

          <p className="super-header">Projektdesigner – Digital strateg</p>
          <h3>Outdoor Buddies</h3>
          <p>Utveckling av en digital tjänst för samordnad administration av
          uthyrning för företag inom Rental as a Service och aktörer i deras
          värdekedja.</p>
          <p className="year">2022–2023</p>

          <p className="super-header">Coach — Facilitator</p>
          <h3>Region Jämtland Härjedalen</h3>
          <p>Coachning och inspiration för företag i besöksnäringen i Åre kommun
          inom digitalisering, cirkulär ekonomi, hållbar destinationsutveckling
          samt nya affärsmodeller. Uppdraget utförs tillsammans
          med <a href="https://www.ptino.se">Simon Oest</a>.</p>
          <p className="year">2021–2022</p>

          <p className="super-header">Strateg — Projektledare</p>
          <h3>Dagens Samhälle</h3>
          <p>Sveriges nyhets- och debattplattform för beslutsfattare inom
          offentlig sektor. Redesign och utveckling av digitala kanaler och
          systemintegrationer samt implementering av nya processer.  <a href="https://www.dagenssamhalle.se/">dagenssamhalle.se</a></p>
          <p className="year">2018–2019</p>

          <figure>
            <blockquote>Aldrig mött ett så proffsigt
            team och aldrig varit med i ett projekt som löpt så smärtfritt och
            samtidigt levererat på en så hög nivå.</blockquote>
            <figcaption>
              Olov Carlsson, chefredaktör
            </figcaption>
          </figure>

          <p className="super-header">Strateg — Projektledare</p>
          <h3>Tengbom</h3>
          <p>Ett av Sveriges ledande arkitektkontor. Research-driven utveckling
          av en ny digital kommunikationsstrategi samt redesign och utveckling
          av företagets webbplats i syfte att göra den till en mer effektiv
          marknadsförings- och säljkanal. <a href="https://www.tengbom.se/">tengbom.se</a></p>
          <p className="year">2016–2019</p>

          <h2>Konst</h2><hr />
          <p className="super-header">Ett hus, en ko, en kvinna</p>
          <h3>Dansens Hus</h3>
          <p>Videokonst till ett verk i tre delar: en installation i form av ett
          socialt rum, en föreställning och en sagostund för vuxna, av
          konstnären <a href="http://www.efvalilja.se/page.php?id=dance&prodid=110&lang=sv">Efva Lilja</a>.</p>
          <p className="year">2015</p>
          <p className="super-header">Walking the Mist</p>
          <h3>Scalamata Exhibition Space, Venice</h3>
          <p>Videokonst till "en koreograferad rörelse av tid genom lager av
          sensuella kroppsliga erfarenheter" av konstnären <a href="http://www.efvalilja.se/page.php?id=film&prodid=81&lang=sv">Efva Lilja</a>.</p>
          <p className="year">2009</p>

          <p className="super-header">Rulla!</p>
          <h3>Forum för Levande Historia</h3>
          <p>Rulla! är ett tyg mönstrat med tankar och åsikter som ingick i
          Forum for Levande Historias Fördomsturné. Tyget skapades i ett
          samarbete mellan Re:design/Streets och konstnären <a href="http://www.piasandstrom.com/">Pia Sandström</a>.</p>
          <p className="year">2005</p>

          <h2>Föreläsningar & panelsamtal</h2><hr />
          <p className="super-header">Almedalen</p>
          <h3>Så ställer vi om fyra av Sveriges sektorer med störst cirkulär potentials</h3>
          <p>Moderator för panelsamtal om hur resursförbrukningen kan minska inom fyra 
            av de mest resurskrävande sektorerna: livsmedel, bygg- och fastighet, mobilitet 
            samt utvinning av metaller och mineraler. Med Cecilia Tall, RE:Source; Jakob Steen, 
            Brukspecialisten; Peter Algurén, Ziklo Bank; Victoria Olsson, Arla Sverige och 
            Camilla Goldbeck-Löwe, Epiroc.</p>
          <p className="year">2024</p>

          <figure>
            <blockquote>Du är den bästa moderatorn i Almedalen!</blockquote>
            <figcaption>
              Feedback från deltagare
            </figcaption>
          </figure>

          <p className="super-header">House Be</p>
          <h3>Framtidens arbetsplats</h3>
          <p>Panelsamtal om vilka krav pandemin och förändringar i omvärlden
          ställer på framtidens arbetsplats. Med Wilhelm Heiroth, projektledare
          på Sweco och Emil Nilsson, kontorschef på CGI. Moderator:
          Olivia Åsberg, Handelskammaren Mittsverige.</p>
          <p className="year">2022</p>
          <p className="super-header">Norrsken House</p>
          <h3>Bubbles of Bliss</h3>
          <p>Föreläsning om projektmetoden som skapar förutsättningar för innovation och
          kreativitet i projekt även när de är hårt styrda av resultatmål,
          budget och tidsramar.</p>
          <p className="year">2019</p>

          <figure>
            <blockquote>We where all blown away! I have NEVER experienced a
            spiritually linked business like yours. It takes courage and a lot
            of authentic honesty to meet people like you did. I am a fan.</blockquote>
            <figcaption>
              Feedback från deltagare
            </figcaption>
          </figure>

          {/* <p className="super-header">Norrsken House</p>
          <h3>Kreativitet ❤︎ Effektivitet</h3>
          <p>Hur arbetar vi målinriktat, inom budget och deadline samtidigt som vi känner kreativitet, inspiration och arbetsglädje? Föreläsning.</p>
          <p className="year">2019</p>
          <p className="super-header">Norrsken House</p>
          <h3>Hållbar arkitektur i ett digitalt samhälle</h3>
          <p>Panelsamtal om arkitektur, hållbarhet och digitalisering med <a href="https://tengbom.se/medarbetare/ivana-kildsgaard/">Ivana Kildsgaard</a>,
          arkitekt och hållbarhetschef på Tengbom, <a href="http://www.beyondintent.net/johangenneby">Johan Genneby</a>,
          medgrundare av Beyond Intent och Sofia Sundström, VD och digital
          strateg på SthlmConnection.
          Moderator: <a href="https://www.vujade.agency/">Jonas Fläckerud</a>.</p>
          <p className="year">2019</p> */}
          <p className="super-header">Åre Business Forum</p>
          <h3>Soffhäng med House Be</h3>
          <p>Panelsamtal om lärdomar från att arbeta remote med kollegor runt
          världen från en liten by i Jämtland. Med Martin Stenberg, entreprenör
          inom energi och termodynamik, och Ulrika Viklund, grundare av House Be.</p>
          <p className="year">2019</p>

        </div>
      </div>
    </section>

  </Layout>
}
